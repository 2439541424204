<template>
	<section class="mb-5">
		<div class="row">
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.contributionGroup') }}</b>
					</div>
					<div class="col-12">
						<select required v-model="employeeCondition.group" class="form-control" :disabled="disabled" @change="onChange">
							<option v-for="(obj, index) in groups" :key="obj.id" :value="obj.id" :selected="employeeCondition.group == index">
								{{ obj.name }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.position') }}</b>
					</div>
					<div class="col-12">
						<InputText :disabled="disabled" v-model="employeeCondition.position" :required="isCompanyAdmin"> </InputText>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.employmentDate') }}</b>
					</div>
					<div class="col-12">
						<InputDate :disabled="disabled" v-model="employeeCondition.employmentDate"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.resignationDate') }}</b>
					</div>
					<div class="col-12">
						<InputDate :disabled="true" v-model="employeeCondition.resignationDate"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.pensionStartDate') }}</b>
					</div>
					<div class="col-12">
						<InputDate :disabled="disabled" v-model="employeeCondition.pensionStartDate"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.healthInsuranceStartDate') }}</b>
					</div>
					<div class="col-12">
						<InputDate :disabled="disabled" v-model="employeeCondition.healthInsuranceStartDate"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1" v-if="$can('Update', 'EmployeePolicy', 'EmployeeCondition.salaryVisible')">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.salaryVisible') }}</b>
					</div>
					<div class="col-12">
						<InputCheck :disabled="disabled" v-model="employeeCondition.salaryVisible" :required="isCompanyAdmin" />
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1"></div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.salary') }}</b>
					</div>
					<div class="col-12" v-if="canReadSalary">
						<InputNumber :disabled="disabled" v-model.number="employeeCondition.salary" :required="isCompanyAdmin"> </InputNumber>
					</div>
					<div class="col-12" v-if="!canReadSalary">Løn er skjult</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.supplementarySalary') }}</b>
					</div>
					<div class="col-12" v-if="canReadSalary">
						<InputNumber :disabled="disabled" v-model.number="employeeCondition.supplementarySalary" :required="isCompanyAdmin"> </InputNumber>
					</div>
					<div class="col-12" v-if="!canReadSalary">Løn er skjult</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.coverSalary') }}</b>
					</div>
					<div class="col-12" v-if="canReadSalary">
						<InputNumber :disabled="true" v-model.number="sumSalary"> </InputNumber>
					</div>
					<div class="col-12" v-if="!canReadSalary">Løn er skjult</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.salaryAdjustment') }}</b>
					</div>
					<div class="col-12">
						<InputDate :disabled="disabled" v-model="employeeCondition.salaryAdjustmentDate" :required="isCompanyAdmin && salaryHasChanged"> </InputDate>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.employeeNumber') }}</b>
					</div>
					<div class="col-12">
						<InputText :disabled="disabled" v-model="employeeCondition.employeeNumber"> </InputText>
					</div>
				</div>
			</div>

			<div class="col-lg-6 col-12 lb pb-1 mt-1">
				<div class="row">
					<div class="col-12">
						<b>{{ $t('employeeConditions.comment') }}</b>
					</div>
					<div class="col-12">
						<InputComment v-model="employeeCondition.comment" :disabled="disabled" />
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputText from '@/modules/global/atomComponents/InputText'
import InputNumber from '@/modules/global/atomComponents/InputNumber'
import InputComment from '@/modules/global/atomComponents/InputComment'
import InputCheck from '@/modules/global/atomComponents/InputCheck'

export default {
	name: 'EmployeeCondition',
	components: {
		InputDate,
		InputText,
		InputNumber,
		InputCheck,
		InputComment,
	},
	props: {
		groups: Array,
		value: Object,
		disabled: Boolean,
		suppliers: Array,
	},
	data() {
		return {
			salaryHasChanged: false,
		}
	},
	mounted() {
		if (this.editableRouter) {
			this.disabled = false
		}
	},
	watch: {
		'employeeCondition.salary': function (newVal, oldVal) {
			this.salaryHasChanged = true
		},
		'employeeCondition.supplementarySalary': function (newVal, oldVal) {
			this.salaryHasChanged = true
		},
	},
	methods: {
		onChange() {
			if (this.employeeCondition.group !== null) {
				// Alert if group is changed
				this.$swal.fire({
					title: 'Advarsel',
					text: 'Medarbejderen vil miste alle valget dækninger når gruppen ændres.',
				})
			}
			this.$emit('onChange', this.employeeCondition.group)
		},
	},
	computed: {
		canReadSalary() {
			if (this.isAdvisor || this.isAdmin) {
				return true
			} else {
				return this.employeeCondition.salaryVisible
			}
		},
		isCompanyAdmin() {
			return this.$store.getters['auth/isCompanyAdmin']
		},
		isAdvisor() {
			return this.$store.getters['auth/isAdvisor']
		},
		isAdmin() {
			return this.$store.getters['auth/isAdmin']
		},
		editableRouter() {
			return this.$route.query.editable
		},
		sumSalary() {
			return this.employeeCondition.salary * 12
		},
		employeeCondition: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>

<style></style>
